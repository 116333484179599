import { createSlice } from "@reduxjs/toolkit";

const initialState = {

  isEnabled: false,
  isCamMicSetup: false,
  isTracking: false,
  invitationId: null,

  micAccess: null,
  audioDeviceId: null,
  
  cameraAccess: null,
  videoDeviceId: null,
  takeCamshot: false,
  camshotInterval: null,
  
  detectPerson: false,
  modelLoaded: null,
  personCount: null,

  trackFullscreen: false,
  fullscreen: null,

  trackTabFocus: false,
  tabFocus: null,

  currQuestion: {
    isTracking: false,
    questionId: null,

    isCameraDeviated: false,
    isFullScreenDeviated: false,
    isFocusDeviated: false,
    hasNoFace: false,
    hasManyFace: false,
  }
}

export const proctoringSlice = createSlice({
  name: "proctoring",
  initialState: initialState,
  reducers: {

    enabledTracking: (state, action) => {
      state.isEnabled = action.payload.isEnabled
      state.invitationId = action.payload.invitationId

      state.takeCamshot = action.payload.takeCamshot
      state.camshotInterval = action.payload.camshotInterval

      state.modelLoaded = null
      state.detectPerson = action.payload.detectPerson

      state.trackFullscreen = action.payload.trackFullscreen
      state.trackTabFocus = action.payload.trackTabFocus
    },
    modelLoaded: (state, action) => {
      state.modelLoaded = action.payload
    },

    startCamMicSetup: (state, action) => {
      if(!state.isEnabled) return
      state.isCamMicSetup = true
    },
    stopCamMicSetup: (state, action) => {
      state.isCamMicSetup = false
    },
    setAudioInput: (state, action) => {
      if(!state.isCamMicSetup) return
      state.audioDeviceId = action.payload
    },
    setVideoInput: (state, action) => {
      if(!state.isCamMicSetup) return
      state.videoDeviceId = action.payload
    },

    startTracking: (state, action) => {
      if(!state.isEnabled) return
      state.isTracking = true
      state.invitationId = action.payload
    },
    stopTracking: (state, action) => {
      state.isTracking = false
      state.invitationId = null
    },

    startQuestionTracking: (state, action) => {
      if(!state.isTracking) return
      state.currQuestion.isTracking = true
      state.currQuestion.questionId = action.payload
      state.currQuestion.isCameraDeviated = null
      state.currQuestion.isFocusDeviated = null
      state.currQuestion.hasNoFace = null;
      state.currQuestion.hasManyFace = null;
    },
    stopQuestionTracking: (state, action) => {
      state.currQuestion.isTracking = false
      state.currQuestion.questionId = null
    },

    micAccess: (state, action) => {
      if(!state.isCamMicSetup && !state.isTracking) return
      const access = action.payload
      state.micAccess = access
      if (state.currQuestion.isTracking) {
        state.currQuestion.isCameraDeviated = !access
      }
    },

    cameraAccess: (state, action) => {
      if(!state.isCamMicSetup && !state.isTracking) return
      const access = action.payload
      state.cameraAccess = access
      if (state.currQuestion.isTracking) {
        state.currQuestion.isCameraDeviated = !access
      }
    },
  
    fullScreenChange: (state, action) => {
      if(!state.isTracking) return
      const isFullScreen = action.payload
      state.fullscreen = isFullScreen;
      if (state.currQuestion.isTracking) {
        state.currQuestion.isFullScreenDeviated = !isFullScreen;
      }
    },
    tabChange: (state, action) => {
      if(!state.isTracking) return
      const focus = action.payload
      const focusMap = { hidden: false, visible: true }
      state.tabFocus = focus
      if (!state.currQuestion.isTracking) return
      state.currQuestion.isFocusDeviated = !focusMap[focus];
    },
    personDetected: (state, action) => {
      if(!state.isTracking) return
      const personCount = action.payload
      state.personCount = personCount
      if (!state.currQuestion.isTracking) return;
      state.currQuestion.hasNoFace = personCount === 0;
      state.currQuestion.hasManyFace = personCount > 1;
    },
  },
});

export const checkIsCamMicSetup = () => (state) => state.proctoring.isCamMicSetup
export const checkIsTracking = () => (state) => state.proctoring.isTracking

export const checkCameraAccess = () => (state) => state.proctoring.cameraAccess 
export const checkMicAccess = () => (state) => state.proctoring.micAccess 

export const getAudioDeviceId = () => (state) => state.proctoring.audioDeviceId
export const getVideoDeviceId = () => (state) => state.proctoring.videoDeviceId

export const shouldTakeCamshot = () => (state) => state.proctoring.takeCamshot
export const getCamshotInterval = () => (state) => state.proctoring.camshotInterval

export const shouldDetectPerson = () => (state) => state.proctoring.detectPerson

export const shouldTrackFullscreen = () => (state) => state.proctoring.takeCamshot
export const shouldTrackTabFocus = () => (state) => state.proctoring.trackTabFocus

export const getCurrQuestionId = () => (state) => state.proctoring.currQuestion.questionId
export const checkIsQuestionTracking = () => (state) => state.proctoring.currQuestion.isTracking
