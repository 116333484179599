import { PROCTORING_OPTIONS } from '../../config';
import { isEmpty } from '../../utils/helper';
import { proctoringSlice } from './slice';
const actions = proctoringSlice.actions

const extractProctoringObj = (data) => {
    const options = data.assessment.antiCheatingTrackingTypes
    const proctoringObj = {}
    for (let i = 0; i < options.length; i++) {
        const key = options[i]
        if(PROCTORING_OPTIONS[key]) proctoringObj[key] = true
    }
    return proctoringObj
}


export const initializeProctoring = (data) => {
    return (dispatch) => {
        const proctoringObj = extractProctoringObj(data)
        const payload = {
            invitationId: data._id,
            isEnabled: !isEmpty(proctoringObj),

            takeCamshot: !!proctoringObj[PROCTORING_OPTIONS.CANDIDATE_PHOTO_CAPTURE],
            camshotInterval: Number(data.assessment.candidatePhotCaptureTimeInSeconds),

            detectPerson: !!proctoringObj[PROCTORING_OPTIONS.FACE_DETECTION],
            trackFullscreen: !!proctoringObj[PROCTORING_OPTIONS.FULL_SCREEN],
            trackTabFocus: !!proctoringObj[PROCTORING_OPTIONS.TAB_SWITCH]
        }
        dispatch(actions.enabledTracking(payload))
    }
}