import * as assmtFlow from '../../pages/AssmntStart/flow';
import * as testFlow from '../../pages/TestModule/flow';
import { assessmentService, testService } from '../../services';
import { flowControlSlice } from './slice';
const slice = flowControlSlice.actions


export const fetchInvite = (inviteId) => {
    return async (dispatch) => {
        try {
            dispatch(slice.fetchInvite());
            const response = await assessmentService.getCandidateInvite(inviteId)
            const _progress = assmtFlow.getTestProgressDTO({})
            const payload = {
                inviteInfo: {
                    _id: response._id,
                    status: response.status,
                    hasExpired: response.hasExpired,
                    invitationUrl: response.invitationUrl
                },
                candidateInfo: {
                    firstName: response.name,
                    lastName: response.lastName,
                    email: response.email,
                },
                assessmentInfo: {
                    _id: response.assessment._id,
                    title: response.assessment.title,
                    time: response.assessment.time,
                },
                progress: _progress,
                steps: Object.entries(_progress).reduce((a, c) => {
                    const [k, v] = c
                    a[k] = !v.skip
                    return a
                }, {}),
                tests: {
                    testList: response.assessment.tests,
                    totalTests: response.assessment.tests.length,
                    currTestStatus: 'idle',
                    currTestIndex: 0
                }
            }
            dispatch(slice.initializeAssessment(payload))
        } catch (error) {
            dispatch(slice.inviteError())
        }
    };
};

export const setInvite = (obj) => {
    return (dispatch) => {
        const _progress = assmtFlow.getTestProgressDTO({ isPublicLink: true })
        const payload = {
            inviteInfo: {
                _id: obj._id,
                status: obj.status,
                hasExpired: obj.hasExpired,
                invitationUrl: null
            },
            candidateInfo: {
                firstName: obj.firstName,
                lastName: obj.lastName,
                email: obj.email,
            },
            assessmentInfo: {
                _id: obj.assessment._id,
                title: obj.assessment.title,
                time: obj.assessment.time,
            },
            progress: _progress,
            steps: Object.entries(_progress).reduce((a, c) => {
                const [k, v] = c
                a[k] = !v.skip
                return a
            }, {}),
            tests: {
                testList: obj.assessment.tests,
                totalTests: obj.assessment.tests.length,
                currTestStatus: 'idle',
                currTestIndex: 0
            }
        }
        dispatch(slice.initializeAssessment(payload))
    }
};
export const fetchAssessment = (assessmentId) => {
    return async (dispatch) => {
        try {
            dispatch(slice.fetchInvite());
            const response = await assessmentService.getAssessment(assessmentId)
            const _progress = assmtFlow.getTestProgressDTO({})
            const payload = {
                inviteInfo: {
                    _id: response._id,
                    status: response.status,
                    hasExpired: response.hasExpired,
                    invitationUrl: response.invitationUrl
                },
                candidateInfo: {
                    firstName: response.name,
                    lastName: response.lastName,
                    email: response.email,
                },
                assessmentInfo: {
                    _id: response.assessment._id,
                    title: response.assessment.title,
                    time: response.assessment.time,
                },
                progress: _progress,
                steps: Object.entries(_progress).reduce((a, c) => {
                    const [k, v] = c
                    a[k] = !v.skip
                    return a
                }, {}),
                tests: {
                    testList: response.assessment.tests,
                    totalTests: response.assessment.tests.length,
                    currTestStatus: 'idle',
                    currTestIndex: 0
                }
            }
            dispatch(slice.initializeAssessment(payload))
        } catch (error) {
            dispatch(slice.inviteError())
        }
    };
};
export const fetchTest = (testId) => {
    return async (dispatch) => {
        try {
            dispatch(slice.fetchTest());
            const response = await testService.fetchTestDetail(testId)
            const data = response.data?.data
            if (!Array.isArray(data)) throw new Error('Custom')
            const [test] = data

            const _progress = testFlow.getTestProgressDTO({ hasPracticeQ: false })
            const _steps = Object.entries(_progress).reduce((a, c) => {
                const [k, v] = c
                a[k] = !v.skip
                return a
            }, {})
            const testInfo = {
                _id: test._id,
                title: test.title,
                logo: test.logo,
                detail: test?.detail,
                skill: test.primarySkill?.label,
                subSkills: test.secondarySkills,
                time: test.time,
                guidelines: test.guidelines.map((item) => item.content),
                aboutUs: test.aboutUs
            }
            const totalAQ = test.questions.length
            const actualQ = test.questions
            const payload = {
                testInfo: testInfo,
                progress: _progress,
                steps: _steps,
                totalAQ: totalAQ,
                actualQ: actualQ,
                isFetching: false,
                error: null
            }
            dispatch(slice.initializeTest(payload))
        } catch (error) {
            console.error(error)
            dispatch(slice.TestError())
        }
    };
}
export const sendOtpAction = (data) => {
    return async (dispatch) => {
        try {
            console.log('sendOtpAction', data)
            await testService.sendOtp(data);
            return Promise.resolve();
        } catch (error) {
            console.log(error);
            // dispatch(slice.submitAnswerFailed());
            return Promise.reject(error);
        }
    };
};
export const verifyOtpAction = (stepKey, data) => {
    return async (dispatch) => {
        try {
            console.log('verifyOtpAction', stepKey, data);

            await testService.verifyOtp(data);
            dispatch(slice.markAssessmentStepComplete(stepKey))
            return Promise.resolve();
        } catch (error) {
            // dispatch(slice.submitAnswerFailed());
            console.log(error);
            return Promise.reject(error);
        }
    };
};
export const saveAnswer = (data) => {
    return async (dispatch) => {
        try {
            const result = await testService.submitAnswer(data).catch((err) => console.error(err))
            console.log(result)
            if(result?.closeTest === true) {
                dispatch(slice.markAssessmentStepComplete(assmtFlow.STEPS.TestWindow.slug))
                return Promise.resolve()
            }
            dispatch(slice.markQuestionComplete())
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            return Promise.reject(error)
        }
    };
};

export const initializeAV2 = (data) => {
    return (dispatch) => {
        const tests = data.tests
        const labs = data.labs
        const enabledProctoring = data.assessment.antiCheatingTrackingTypes.length > 0
        const questions = data.qualifyingQuestions || []
        const hasQualifying = questions.length > 0
        const hasLabs = labs.length > 0
        const hasTests = tests.length > 0
        const assessmentProgress = assmtFlow.getTestProgressDTO({ enabledProctoring, hasQualifying, hasTests, hasLabs })
        const assessmentSteps = Object.entries(assessmentProgress).reduce((a, c) => {
            const [k, v] = c
            a[k] = !v.skipped
            return a
        }, {})
        const flowControl = {
            assessment: {
                __initialized: true,
                steps: assessmentSteps,
                progress: assessmentProgress,
                qualifyingQuestions: questions.length,
                tests: {
                    totalTests: tests.length,
                    currTestIndex: 0,
                    testStarted: false
                },
                labs: {
                    totalLabs: labs.length,
                    lastLabIndex: labs.length - 1,
                    currLabIndex: 0,
                    currLabStatus: 'PENDING'
                },
            },
            qualifyingWindow: {
                totalQuestions: questions.length,
                currentIndex: 0,
                lastIndex: questions.length - 1
            }
  
        }
        dispatch(slice.initializeAV2(flowControl))
    }
}

export const updateAV2 = (data) => {
    return (dispatch) => {
        dispatch(slice.initializeAV2(data))
    }
};

export const initializeQV2 = (data) => {
    return (dispatch) => {
        const questions = data.qualifyingQuestions
        const payload = {
            totalQuestions: questions.length,
            currentIndex: 0,
            lastIndex: questions.length - 1
        }
        dispatch(slice.initializeQV2(payload))
    }
}

export const updateQV2 = (data) => {
    return (dispatch) => {
        dispatch(slice.initializeQV2(data))
    }
}


export const initializeTV2 = (data, i) => {
    return (dispatch) => {
        const tests = data.tests
        const currentTest = tests[i]
        const testProgress = testFlow.getTestProgressDTO({ hasPracticeQ: false })
        const testSteps = Object.entries(testProgress).reduce((a, c) => {
            const [k, v] = c
            a[k] = !v.skip
            return a
        }, {})

        const payload = {
            __initialized: true,
            index: i,
            steps: testSteps,
            progress: testProgress,
            totalAQ: currentTest.questions.length,
            currAQIndex: 0,
            isSaving: false
        }
        dispatch(slice.initializeTV2(payload))
    }
}

export const updateTV2 = (data) => {
    return (dispatch) => {
        dispatch(slice.initializeTV2(data))
    }
}