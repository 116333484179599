import axiosInstance from "../utils/axios"

export const getInvite = async(id) => {
    return axiosInstance.get(`/api/assessment/invite`)
}
export const getCandidateInvite = async(id) => {
    return axiosInstance.get(`/api/candidates/invite/${id}`)
}
export const getAssessment = async(id) => {
    return axiosInstance.get(`/api/candidates/assessment/${id}`)
}
export const getAssessmentResult = async(params = {}) => {
    return axiosInstance.get(`/api/evaluation/result`, { params })
}
export const getAllInvites = async() => {
    return axiosInstance.get(`/api/candidates/get-invites`)
}