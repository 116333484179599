import Cookies from "js-cookie";
import axiosInstance from "../utils/axios";
import client from "./lib/client";
import { COOKIE_NAMES } from "../config/constants";
import { toast } from "react-hot-toast";

export const fetchTestDetail = (id) => {
    return client.get(`/api/tests/detail/${id}`)
}

export const proctorAns = (data) => {
    return axiosInstance.post(`/api/evaluation/proctorAns`, data)
}

export const submitAnswer = (data) => {
    return axiosInstance.post(`/api/evaluation/submitAns`, data)
}

export const submitQualifyingAnswer = (data) => {
    return axiosInstance.post(`/api/evaluation/qualifying/submitAns`, data)
}

export const sendOtp = (data) => {
    return axiosInstance.post(`/api/candidates/send-otp`, data)
}

export const verifyOtp = (data) => {
    return axiosInstance.post(`/api/candidates/verify-otp`, data).catch((err) => {
        let errMsg = ''
        if(err.response){
            if(err?.response?.data.msg === 'Assessment you entered is already registered'){
                errMsg = '!!! You have already completed this assessment'
            }else if(err?.response?.data.msg === 'Email_1_assessment you entered is already registered'){
                errMsg = '!!! You have already completed this assessment'
            }else if(err?.response?.data.msg === 'Invalid otp'){
                errMsg = 'Please enter valid otp'
            }else{
                errMsg = err?.response?.data.msg || 'Something went wrong!'
            }
        }
        toast.error(errMsg)
        throw err
    })
}

export const socialLogin = (data) => {
    return client.post(`/api/candidates/social-login`, data)
}

export const verifyInvite = async(data) => {
    return axiosInstance.post('api/assessment/verify-invitation', data)
}
