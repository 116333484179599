export const BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:8000';
export const screenshotType = 'image/png';
export const camShotInterval = 1000*10;
export const appName = 'Cyberforce';

export const GOOGLE_MAP = {
    API_KEY: process.env.REACT_APP_GOOGLE_MAP_API_KEY
}

export const GOOGLE_OAUTH = {
    CLIENT_ID: process.env.REACT_APP_GOOGLE_CLIENT_ID
}

export const LINKED_IN_OAUTH = {
    SCOPE: 'openid email profile',
    CLIENT_ID: process.env.REACT_APP_LINKEDIN_CLIENT_ID,
    REDIRECT_URI: process.env.REACT_APP_LINKEDIN_REDIRECT_URL
}

export const INVITATION_LINK_STATUS = {
    VALID: 'VALID',
    INVALID: 'INVALID',
    EXPIRED: 'EXPIRED',
    COMPLETED: 'COMPLETED',
    INSUFFICIENT_CREDITS: 'INSUFFICIENT_CREDITS',
}

export const PROCTORING_OPTIONS = {
    FULL_SCREEN: 'FULL_SCREEN',
    TAB_SWITCH: 'TAB_SWITCH',
    FACE_DETECTION: 'FACE_DETECTION',
    CANDIDATE_PHOTO_CAPTURE: 'CANDIDATE_PHOTO_CAPTURE',
}