import { createSlice } from "@reduxjs/toolkit";
import { STEPS as assessmentSteps } from '../../pages/AssmntStart/flow'
const initialState = {
    assessment: {
        inviteInfo: null,
        candidateInfo: null,
        assessmentInfo: null,
        steps: {},
        progress: {},
        tests: {
            testList: [],
            totalTests: 0,
            currTestIndex: 0,
            currTestStatus: 'idle', // 'idle', 'loading', 'success', 'failed'
        },
        qualifyingQuestions: 0,
        isFetching: false,
        error: null
    },
    qualifyingWindow: {
        totalQuestions: 0,
        currentIndex: 0,
        lastIndex: 0
    },
    currentTest: {
        testInfo: null,
        steps: {},
        progress: {},
        practiceQ: [],
        actualQ: [],
        totalPQ: 0,
        totalAQ: 0,
        currPQIndex: 0,
        currAQIndex: 0,
        isFetching: false,
        error: null,
        isSaving: false,
    }
};

const initialStateV2 = {
    assessment: {
        __initialized: false,
        steps: {},
        progress: {},
        tests: {
            totalTests: 0,
            currTestIndex: 0,
            testStarted: false
        },
        labs: {
            totalLabs: 0,
            currLabIndex: 0,
            currLabStatus: 'PENDING'
        }
    },
    qualifyingWindow: {
        totalQuestions: 0,
        currentIndex: 0,
        lastIndex: 0
    },
    currentTest: {
        __initialized: false,
        index: 0,
        steps: {},
        progress: {},
        totalAQ: 0,
        currAQIndex: 0
    }
};

export const flowControlSlice = createSlice({
    name: "flowControl",
    initialState: initialStateV2,
    reducers: {
        initializeState: (state, action) => {
            state.assessment = action.payload.assessment
            state.currentTest = action.payload.currentTest
        },
        fetchInvite: (state, action) => {
            state.assessment.isFetching = true
            state.assessment.error = null
        },
        inviteError: (state, action) => {
            state.assessment.isFetching = false
            state.assessment.error = 'Error'
        },
        initializeAssessment: (state, action) => {
            const { payload } = action
            state.assessment.inviteInfo = payload.inviteInfo
            state.assessment.candidateInfo = payload.candidateInfo
            state.assessment.assessmentInfo = payload.assessmentInfo
            state.assessment.steps = payload.steps
            state.assessment.progress = payload.progress
            state.assessment.tests = payload.tests
            state.assessment.isFetching = false
            state.assessment.error = null

        },
        fetchTest: (state, action) => {
            state.currentTest.isFetching = true
            state.currentTest.error = null
            state.assessment.tests.currTestStatus = 'loading'
        },
        TestError: (state, action) => {
            state.currentTest.isFetching = false
            state.currentTest.error = 'Error'
            state.assessment.tests.currTestStatus = 'failed'
        },
        initializeTest: (state, action) => {
            const { payload } = action
            state.currentTest.testInfo = payload.testInfo
            state.currentTest.steps = payload.steps
            state.currentTest.progress = payload.progress
            state.currentTest.practiceQ = payload.practiceQ
            state.currentTest.actualQ = payload.actualQ
            state.currentTest.totalPQ = payload.totalPQ
            state.currentTest.totalAQ = payload.totalAQ
            state.currentTest.currPQIndex = 0
            state.currentTest.currAQIndex = 0
            state.currentTest.isFetching = false
            state.currentTest.error = null
            state.assessment.tests.currTestStatus = 'success'
        },
        markAssessmentStepComplete: (state, action) => {
            const stepKey = action.payload
            state.assessment.progress[stepKey].completed = true
        },
        markTestStepComplete: (state, action) => {
            const stepKey = action.payload
            state.currentTest.progress[stepKey].completed = true
        },
        markTestComplete: (state, action) => {
            state.assessment.tests.currTestIndex += 1
            state.currentTest.index += 1
            state.currentTest.__initialized = false
            // state.assessment.tests.currTestStatus = 'idle'
        },
        submitAnswer: (state, action) => {
            state.currentTest.isSaving = true
        },
        markQualifyingQuestionComplete: (state, action) => {
            state.qualifyingWindow.currentIndex += 1
        },
        markQuestionComplete: (state, action) => {
            const { payload } = action
            if (payload?.isPractice) state.currentTest.currPQIndex += 1
            else state.currentTest.currAQIndex += 1
            state.currentTest.isSaving = false
        },
        initializeAV2: (state, action) => {
            const { payload } = action
            state.assessment = payload.assessment
            state.qualifyingWindow = payload.qualifyingWindow
        },
        initializeTV2: (state, action) => {
            const { payload } = action
            if(payload.__initialized){
                state.assessment.tests.testStarted = true
            }
            state.currentTest = payload
        },
        terminateAssessment: (state, action) => {
            const progress = JSON.parse(JSON.stringify(state.assessment.progress))
            for(let step in progress){
                if(!!progress[step].completed || step === assessmentSteps.Terminated.slug) continue
                progress[step].skipped = true
            }
            state.assessment.progress = progress
        },
        markLabCompleted: (state, action) => {
            if(state.assessment.labs.lastLabIndex <= state.assessment.labs.currLabIndex){
                state.assessment.progress[assessmentSteps.LabWindow.slug].completed = true
                return
            }
            state.assessment.labs.currLabIndex += 1
            state.assessment.labs.currLabStatus = 'PENDING'
        },
    },
});