import { assessmentService } from '../../services';
import { assessmentSlice } from "./slice";
const { actions: slice } = assessmentSlice;


export const getInviteDetail = (id) => {
  return async (dispatch) => {
    try {
        const data = await assessmentService.getCandidateInvite(id);
        const final = {
          ...data,
          totalTests: data?.assessment?.tests?.length,
          testList: data?.assessment?.tests
        }
        dispatch(slice.setAssessment(final));
        return Promise.resolve(final);
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  };
};

export const fetchResult =async (id) => {
  // return async (dispatch) => {
    try {
        const data = await assessmentService.getAssessmentResult();
        // dispatch(slice.setAssessment(final));
        return Promise.resolve(data);
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  // };
};

export const fetchAllInvites =async () => {
    try {
        const data = await assessmentService.getAllInvites();
        return Promise.resolve(data);
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
};

export const invitationSuccess = (inviteData) => {
  return (dispatch) => {
    const payload = { data: inviteData, error: null,}
    dispatch(slice.setAssessment(payload))
  }
};

export const invitationError = (error) => {
  return (dispatch) => {
    const payload = { data: null, error: error.message,}
    dispatch(slice.setAssessment(payload))
  }
};
