import * as React from "react";
import { Navigate } from "react-router-dom";
import { PAGE_PATH } from '../../router/path'
const MockInviteLink = () => {
  const claims = { inviteId: 'mocking-bird' }
  const token = btoa(`header.${JSON.stringify(claims)}.signature`)
  return <Navigate to={`${PAGE_PATH.LINK.INVITE}?token=${token}`} />
};

export default MockInviteLink;
