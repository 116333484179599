import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import App from "./App";
import store from "./redux/store";

// Bootstrap Bundle JS
import "bootstrap/dist/js/bootstrap.bundle.min";
import "./index.scss";

async function enableMocking() {
  if (process.env.REACT_APP_MOCKING !== 'ENABLED') {
    return Promise.resolve()
  }
  const { worker } = await import('./mocks')
  return worker.start({
    onUnhandledRequest(req) {
      console.error(
        'Found an unhandled %s request to %s',
        req.method,
        req.url.href,
      )
    },
  })
}

const root = ReactDOM.createRoot(document.getElementById("root"));
enableMocking().then(() => {
  root.render(
    <Provider store={store}>
      <App />
    </Provider>
  );
})


