import { Toaster } from "react-hot-toast";
import { ThemeProvider } from "@mui/material";
import { ToastContainer } from "react-toastify";
import { RouterProvider } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";

import { router } from "./router";
import { customTheme } from "./assets/themes/MUI-override/customTheme";

import "react-toastify/dist/ReactToastify.css";

import { GOOGLE_OAUTH } from "./config";
import AuthProvider from "./contexts/AuthProvider";

function App() {
  return (
    <ThemeProvider theme={customTheme}>
      <ToastContainer />
      <GoogleOAuthProvider clientId={GOOGLE_OAUTH.CLIENT_ID}>
        <AuthProvider>
          <RouterProvider router={router} />
        </AuthProvider>
      </GoogleOAuthProvider>
      <Toaster
        toastOptions={{
          position: "top-right",
          style: {
            background: "#625afa",
            color: "#fff",
          },
        }}
      />
    </ThemeProvider>
  );
}

export default App;
