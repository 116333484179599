export const STEPS = {
    UserConfirm: {
        slug: 'UserConfirm',
        order: 1
    },
    Introduction: {
        slug: 'Introduction',
        order: 2
    },
    CameraMicSetup: {
        slug: 'CameraMicSetup',
        order: 3
    },
    GuidelineAssessment: {
        slug: 'GuidelineAssessment',
        order: 4
    },
    QualifyingWindow: {
        slug: 'QualifyingWindow',
        order: 5
    },
    TestWindow: {
        slug: 'TestWindow',
        order: 5
    },
    LabWindow: {
        slug: 'LabWindow',
        order: 6
    },
    Submitted: {
        slug: 'Submitted',
        order: 7
    },
    Terminated: {
        slug: 'Terminated',
        order: 8
    },
}

export const getTestProgressDTO = ({ enabledProctoring = false, hasQualifying = false, hasTests = false, hasLabs = false }) => {
    return {
        [STEPS.UserConfirm.slug]: {
            completed: true
        },
        [STEPS.Introduction.slug]: {
            completed: false
        },
        [STEPS.CameraMicSetup.slug]: {
            skipped: !enabledProctoring,
            completed: false
        },
        [STEPS.GuidelineAssessment.slug]: {
            completed: false
        },
        [STEPS.QualifyingWindow.slug]: {
            skipped: !hasQualifying,
            completed: false
        },
        [STEPS.TestWindow.slug]: {
            skipped: !hasTests,
            completed: false
        },
        [STEPS.LabWindow.slug]: {
            skipped: !hasLabs,
            completed: false
        },
        [STEPS.Submitted.slug]: {
            completed: false
        },
        [STEPS.Terminated.slug]: {
            completed: false
        },
    }
}
