import * as Yup from 'yup'

const INVITE_STATUS = ['PENDING', 'IN_PROGRESS', 'COMPLETED']
const Question_Types = ['MULTIPLE_CHOICE']

export const invitationSchema = Yup.object({
  _id: Yup.string(),
  flowType: Yup.string().required(),
  status: Yup.mixed().oneOf(INVITE_STATUS).required(),
  email: Yup.string().required().email(),
  candidate: Yup.string().required(),
  firstName: Yup.string().optional().default("Guest"),
  lastName: Yup.string().optional().default(""),
  assessment: Yup.object({
    title: Yup.string().nullable(),
    logo: Yup.string().nullable(),
    time: Yup.number(),
    totalTime: Yup.number(),
    antiCheatingTrackingTypes: Yup.array().of(Yup.string()),
    candidatePhotCaptureTimeInSeconds: Yup.number(),
    description: Yup.string().optional(),
    labArrOfObj: Yup.array().of(
      Yup.object({
        _id: Yup.string(),
        name: Yup.string(),
        displayName: Yup.string(),
        durationInMinutes: Yup.number()
      })
    ),
  }),
  job: Yup.object({
    _id: Yup.string().nullable(),
    logo: Yup.string().nullable(),
  }).nullable(),
  summary: Yup.object({
    timer: Yup.object()
      .shape({
        timeLeft: Yup.number(),
      })
      .optional(),
    flowControl: Yup.mixed().optional(),
  }),
  qualifyingQuestions: Yup.array().of(
    Yup.object({
      _id: Yup.string().required(),
      questionType: Yup.mixed().oneOf(Question_Types),
      detail: Yup.object({
        langWiseDetail: Yup.array().of(
          Yup.object({
            _id: Yup.string().required(),
            language: Yup.string().required(),
            detail: Yup.string(),
          })
        ),
      }),
      ansTitle: Yup.string().required(),
      answers: Yup.array()
        .of(
          Yup.object({
            _id: Yup.string().required(),
            answer: Yup.string().required(),
          })
        )
        .required()
        .min(1),
    })
  ),
  tests: Yup.array().of(
    Yup.object({
      _id: Yup.string(),
      title: Yup.string(),
      detail: Yup.string(),
      aboutUs: Yup.string(),
      guidelines: Yup.array().of(
        Yup.object({
          _id: Yup.string(),
          content: Yup.string(),
        })
      ),
      logo: Yup.string().nullable(),
      questions: Yup.array().of(
        Yup.object({
          _id: Yup.string().required(),
          questionType: Yup.mixed().oneOf(Question_Types),
          secondarySkills: Yup.array().of(Yup.string()),
          detail: Yup.object({
            langWiseDetail: Yup.array().of(
              Yup.object({
                _id: Yup.string().required(),
                language: Yup.string().required(),
                detail: Yup.string(),
              })
            ),
          }),
          ansTitle: Yup.string().required(),
          answers: Yup.array()
            .of(
              Yup.object({
                _id: Yup.string().required(),
                answer: Yup.string().required(),
              })
            )
            .required()
            .min(1),
          correctCount: Yup.number().required().min(1),
        })
      ),
    })
  ),
  labs: Yup.array().of(Yup.string()),
});