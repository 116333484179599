import axiosInstance from "../utils/axios"

export const navigateToLab = async(labId, invitationId, params) => {
    const redirectUrl = await axiosInstance.get(`/api/labs/token/${labId}/${invitationId}`, { params })
    if(redirectUrl){
        // debugger;
        window.location.href = redirectUrl
    }
}

// this is just for mocking and lab testing there is no any real api like this
export const stopLab = async(labId) => {
    return axiosInstance.put(`/api/labs/${labId}/stop`)
}
