import * as React from "react";
import { useSelector } from "react-redux";

const Loadable = (Component) => (props) => {
  return (
      <React.Suspense fallback={null}>
          <Component {...props} />
      </React.Suspense>
  )
}


// Lazy loadable components...
const TabProctoring = Loadable(React.lazy(() => import('./tab')))
const CameraProctoring = Loadable(React.lazy(() => import('./camera')))
const FullScreenProctoring = Loadable(React.lazy(() => import('./fullscreen')))
const MicProctoring = Loadable(React.lazy(() => import('./mic')))


const Proctoring = () => {
  
  const shouldProctor = useSelector((state) => state.proctoring.isEnabled)
  const takeCamshot = useSelector((state) => state.proctoring.takeCamshot)
  const detectPerson = useSelector((state) => state.proctoring.detectPerson)
  const trackFullscreen = useSelector((state) => state.proctoring.trackFullscreen)
  const trackTabFocus = useSelector((state) => state.proctoring.trackTabFocus)
  const enableCamera = (!!takeCamshot || !!detectPerson)

  return (
    <>
      {shouldProctor && <MicProctoring />}
      {trackTabFocus && <TabProctoring />}
      {enableCamera && <CameraProctoring />}
      {trackFullscreen && <FullScreenProctoring />}
    </>
  )
};

export default Proctoring;
