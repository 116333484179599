import {createTheme} from "@mui/material";

export const customTheme = createTheme({
    palette: {
        success:{main: '#29CC97'},
        error:{main: '#F64E60'},
        secondary: {main: '#9FA2B4'},
        primary:{main: '#00A3FF'},
        text: {primary: '#212121', secondary: '#B5B5C3'}
    },
    typography: {
        fontFamily: "Roboto",
        h1: {
            fontWeight: "600",
            fontSize: "3rem",
            fontFamily: "Poppins",
        },
        h2:{
            fontWeight: "600",
            fontSize: "1.875rem",
            fontFamily: "Poppins",
        },
        h3:{
            fontWeight: "600",
            fontSize: "1.5rem",
            fontFamily: "Poppins",
        },
        h4: {
            fontWeight: "600",
            fontSize: "1rem",
            fontFamily: "Poppins",
        },
        h5: {
            fontWeight: "500",
            fontSize: ".875rem",
            fontFamily: "Roboto",
        },
        body2: {
            fontSize: ".875rem"
        },
        headingMedium: {
            fontSize: "1.75rem",
            lineHeight: "2.0625rem",
        }
    },
    components: {
        MuiButton:{
            styleOverrides: {
                root: {
                    textTransform: "capitalize",
                    borderRadius: ".375rem",
                    fontWeight: "600",
                    boxShadow: "none",
                },
                contained: {
                    border: `.0625rem solid`,
                    padding: "5px 15px",
                    '&:hover': {
                        boxShadow: "none",
                    },
                },
                containedError:{
                   borderColor: `#F64E60`,
                    '&:hover': {
                        borderColor: `#F64E60`,
                    },
                },
                containedPrimary:{
                    color: `#fff`,
                    borderColor: `#00A3FF`,
                    '&:hover': {
                        borderColor: `#00A3FF`,
                    },
                },
            }
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    boxShadow: "none",
                    borderRadius:".75rem",
                    color: "#3F4254"
                }
            }
        },
        MuiMenu:{
           styleOverrides:{
               root: {
                   boxShadow: '0px 0px 50px rgba(0, 0, 0, 0.15)',
                   borderRadius: '5px'
               }
           }
        },
        MuiOutlinedInput: {
            styleOverrides:{
                root: {
                    borderRadius: `.375rem`
                }
            }
        },
        MuiTable: {
            styleOverrides: {
                root: {
                    borderColor: "#E4E6EF",
                    fontFamily: 'Roboto'
                }
            }
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    borderColor: "#B5B5C3"
                }
            }
        },
    },
})