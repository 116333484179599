import * as React from 'react'
import { LinkedInCallback } from 'react-linkedin-login-oauth2';
import { Navigate, Outlet, createBrowserRouter } from "react-router-dom";

import { PAGE_PATH } from './path';
import PageLoader from '../Components/Loader/PageLoader';



import AuthGuard from '../guards/AuthGuard';
import InviteGuard from '../guards/InviteGuard';
import MockInviteLink from '../pages/LinkLandingPage/MockInviteLink';
import Proctoring from '../proctoring';

const Loadable = (Component) => (props) => {
    return (
        <React.Suspense fallback={<PageLoader />}>
            <Component {...props} />
        </React.Suspense>
    )
}

// Lazy loadable pages...
const InviteLinkPage = Loadable(React.lazy(() => import('../pages/LinkLandingPage/InviteLink')))
const PublicLinkPage = Loadable(React.lazy(() => import('../pages/LinkLandingPage/PublicLink')))
const HomPage = Loadable(React.lazy(() => import('../pages/Home/Home')))
const AssessmentPage = Loadable(React.lazy(() => import('../pages/AssmntStart/Assessment')))
const FakeLabPage = Loadable(React.lazy(() => import('../mocks/pages/FakeLab')))

export const router = createBrowserRouter([
    {
        path: PAGE_PATH.ROOT,
        element: (
            <AuthGuard>
                <HomPage />
            </AuthGuard>
        )
    },
    {
        path: PAGE_PATH.LINKEDIN,
        element: <LinkedInCallback />
    },
    {
        path: PAGE_PATH.LINK.ROOT,
        element: <Outlet />,
        children: [
            {
                path: PAGE_PATH.LINK.PUBLIC(':id'),
                element: <PublicLinkPage />
            },
            {
                path: PAGE_PATH.LINK.INVITE,
                element: <InviteLinkPage />
            },
            {
                path: PAGE_PATH.LINK.MOCK_INVITE,
                element: <MockInviteLink />
            },
            {
                path: '*',
                element: <Navigate to={PAGE_PATH.ROOT} />
            }
        ]

    },
    {
        path: PAGE_PATH.ASSESS_INVITE(':id'),
        element: (
            <InviteGuard>
                <Proctoring />
                <AssessmentPage />
            </InviteGuard>
        )
    },
    {
        path: PAGE_PATH.FAKE_LAB(':id'),
        element: <FakeLabPage />
    },
    // {
    //     path: '*',
    //     element: <Navigate to={PAGE_PATH.ROOT} />
    // },
])