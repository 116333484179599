/* eslint-disable jsx-a11y/alt-text */
import * as React from 'react'
import styles from './authenticate-candidate.module.scss'
import IMAGES from "../../assets/images/images";
import VerifyOTP from './VerifyOTP';
import SendOtp from './SendOtp';

const AuthenticateCandidate = ({ assessmentId }) => {

    const [name, setName] = React.useState('')
    const [email, setEmail] = React.useState('')
    const [inviteId, setInviteId] = React.useState(null)
    const [step, setStep] = React.useState('Enter_Email') // Enter_Email, Otp_sent

    return (
        <section className={'bg-white'}>
            <div className={`d-flex flex-column flex-md-row ${styles.loginWrap}`}>
                <div className={`full-page panel-md-50 pd-0 ${styles.banner}`}>
                    <img className={`img-fluid ${styles.loginBannerMob}`} src={IMAGES.LoginBanner  } />
                </div>
                <div className={`full-page panel-md-50 pd-y-20 pd-x-30 d-flex align-items-center justify-content-center ${styles.loginRight}`}>
                    {step === 'Otp_sent' ?
                        <VerifyOTP assessmentId={assessmentId} email={email} name={name} inviteId={inviteId} setStep={setStep} /> :
                        <SendOtp email={email} setEmail={setEmail} name={name} setName={setName} setStep={setStep} assessmentId={assessmentId} setInviteId={setInviteId} />
                    }
                    {/* <div className={`${styles.poweredByWrap}`}>
                        <img src={IMAGES.PoweredByCyberForce} />
                    </div> */}
                </div>
            </div>
        </section>
    )
}

export default AuthenticateCandidate