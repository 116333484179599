import { combineReducers } from "redux";
import { assessmentSlice} from './assessment/slice'
import { flowControlSlice } from "./flowControl/slice";
import { proctoringSlice } from "./proctoring/slice";

export const rootReducer = combineReducers({
  assessment: assessmentSlice.reducer,
  flowControl: flowControlSlice.reducer,
  proctoring: proctoringSlice.reducer,
});
